import { AxiosRequestConfig } from 'axios';

import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';
import { CinemaDto } from '../../models/cinema/CinemaDto.types';
import { CinemaInputDto } from '../../models/cinema/CinemaInputDto';
import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { CinemaListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

import { CinemaApiEmbed } from './cinema-api.embed';

// <------------------------ Single Cinema ------------------------>

/**
 * This endpoint returns a single cinema.
 *
 * @example
 * // Get the details of a single cinema by cinemaId or slug:
 * getCinemaById(1);
 *
 * @param id
 * @param accessToken
 * @returns {ApiResponse<CinemaDto>}
 */

export async function getCinemaById(id: number | string, accessToken?: string): Promise<ApiResponse<CinemaDto>> {
    // TODO: this should be a part of the apiClient instantiation in cineamo-frontend-lib/src/api/cineamoApiClient.ts
    const config: AxiosRequestConfig = {};
    if (accessToken) {
        config.headers = { Authorization: `Bearer ${accessToken}` };
    }

    return apiClient
        .get('/cinemas/' + id, config)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Filtered Cinema List ------------------------>

export type GetCinemasQueryParameter = {
    isCustomer?: boolean;
    isActive?: boolean;
    countryCode?: string;
    employedUserId?: string | number;
    favoritedUserId?: string | number;
    cinemaChainId?: string | number;
    longitude?: number;
    latitude?: number;
    distance?: number;
    embed?: CinemaApiEmbed[];
    metricStartDatetime?: string;
    metricEndDatetime?: string;
} & UrlQueryParameter;

/**
 * This endpoint returns a filtered list of cinemas.
 *
 * @example
 * // Get a list cinemas filtered according to query parameters used:
 * getCinemas({isActive: true});
 *
 * @returns {ApiResponse<CinemaListResponse>}
 */

export async function getCinemas(
    params?: GetCinemasQueryParameter,
    config?: AxiosRequestConfig
): Promise<ApiResponse<CinemaListResponse>> {
    return apiClient
        .get(`/cinemas${buildUrlQuery(params)}`, config)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Create Cinema ------------------------>

/**
 * This endpoint creates a single cinema and returns a cinema object or an error.
 *
 * @example
 * // Create a single cinema from form data:
 * createCinema({...cinema});
 *
 * @param cinema
 * @returns {ApiResponse<CinemaDto> | ApiErrorResponse<CinemaDto>}
 */

export async function createCinema(
    cinema: CinemaInputDto
): Promise<ApiResponse<unknown, CinemaDto> | ApiErrorResponse<CinemaInputDto>> {
    return apiClient
        .post('/cinemas', cinema)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Update Cinema ------------------------>

/**
 * This endpoint updates a single cinema and returns the updated cinema object or an error.
 *
 * @example
 * // Update the details of a single cinema from form data:
 * updateCinema({isActive: true});
 *
 * @param cinema
 * @returns {ApiResponse<CinemaDto>}
 */

export async function updateCinema(
    cinema: CinemaInputDto
): Promise<ApiResponse<CinemaDto> | ApiErrorResponse<CinemaInputDto>> {
    return apiClient
        .patch(`cinemas/${cinema.id}`, cinema)
        .then((res) => res.data)
        .catch((error) => error);
}

export async function cinemaShowtimesSync(cinemaId: string | number): Promise<ApiResponse | ApiErrorResponse> {
    return apiClient
        .post(`cinemas/${cinemaId}/showtimes-sync`)
        .then((res) => res.status === 204)
        .catch((error) => error);
}
