import {CustomSVGProps} from "../../types/CustomSVGProps.types";

export function IconDeselect48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M0 4C0 1.79086 1.79086 0 4 0H44C46.2091 0 48 1.79086 48 4V44C48 46.2091 46.2091 48 44 48H4C1.79086 48 0 46.2091 0 44V4Z"
                  fill={props.fill || 'currentColor'}/>
            <path d="M8 22H40V26H8V22Z"
                  fill={props.foregroundcolor || '#C4C4C4'}/>
        </svg>
    );
}

export function IconDeselect24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2Z"
                  fill={props.fill || 'currentColor'}/>
            <path d="M4 11H20V13H4V11Z"
                  fill={props.foregroundcolor || '#C4C4C4'}/>
        </svg>
    );
}

export function IconDeselect16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16"
             height="16"
             viewBox="0 0 16 16"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M0 2C0 0.895431 0.895431 0 2 0H14C15.1046 0 16 0.895431 16 2V14C16 15.1046 15.1046 16 14 16H2C0.895431 16 0 15.1046 0 14V2Z"
                  fill={props.fill || 'currentColor'}/>
            <path d="M4 7H12V9H4V7Z"
                  fill={props.foregroundcolor || '#C4C4C4'}/>
        </svg>
    );
}
