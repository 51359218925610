import { CustomSVGProps } from '../../types/CustomSVGProps.types';

export function IconUpArrow48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48" 
             viewBox="0 0 48 48" 
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M24 0.585785L35.7071 12.2929L34.2929 13.7071L24 3.41421L13.7071 13.7071L12.2929 12.2929L24 0.585785Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M23 48L23 2L25 2L25 48L23 48Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconUpArrow24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24" 
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M12 3.41422L17.2929 8.70711L18.7071 7.29289L12 0.585789L5.29289 7.29289L6.70711 8.70711L12 3.41422Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M11 24L11 2L13 2L13 24L11 24Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconUpArrow16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" 
             height="16" 
             viewBox="0 0 16 16"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M8 0.585787L13.7071 6.29289L12.2929 7.70711L8 3.41421L3.70711 7.70711L2.29289 6.29289L8 0.585787Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7 16L7 3L9 3L9 16L7 16Z"
                  
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}