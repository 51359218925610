import * as process from 'process';

import { DateTime } from 'luxon';
import Link from 'next/link';
import React, { useState } from 'react';

import ArrowCellContent from '@/components/ui/dashboard/sub-components/ArrowCellContent';
import MACellContent from '@/components/ui/dashboard/sub-components/MACellContent';
import ListLoadingSpinner from '@cineamo/legacy-frontend-lib/src/components/ui/animation/ListLoadingSpinner';
import Button, { ButtonStyle } from '@cineamo/legacy-frontend-lib/src/components/ui/buttons/Button';

import { getCinemas } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api';
import { CinemaApiEmbed } from '@cineamo/legacy-frontend-lib/src/api/cinema/cinema-api.embed';

import { usePagination } from '@cineamo/legacy-frontend-lib/src/hooks/usePagination';

import { IconDeselect16 } from '@cineamo/legacy-frontend-lib/src/icons/symbols/ic-deselect';

type CinemaTopUserGrowthTableProps = {
    startDatetime: DateTime;
    endDatetime: DateTime;
    locale: string;
};

export default function CinemaTopUserGrowthTable(props: CinemaTopUserGrowthTableProps) {
    const { startDatetime, endDatetime, locale } = props;

    const [filterByMA, setFilterByMA] = useState(null);

    // Fetch the top user growth
    const { data: cinemaUserGrowthTopResponse, isLoading: isLoadingCinemaUserGrowthTopResponse } = usePagination(
        ['cinemas-user-growth-top', startDatetime, filterByMA],
        async (pageParam) =>
            await getCinemas({
                page: pageParam,
                embed: [
                    CinemaApiEmbed.METRIC_CUSTOMER_COUNT,
                    CinemaApiEmbed.METRIC_CUSTOMERS_TOTAL_COUNT,
                    CinemaApiEmbed.CINEMA_CONFIGURATION
                ],
                metricStartDatetime: startDatetime,
                metricEndDatetime: endDatetime,
                sort: 'metricCustomersCount',
                order: 'desc',
                hasMarketingAssistantBooked: filterByMA
            }),
        {
            staleTime: 1,
            elementIdOrClass: 'loader-element-cinemas-top'
        }
    );

    const topCinemasWithUserGrowthMetric =
        cinemaUserGrowthTopResponse?.pages?.flatMap((page) => page._embedded['cinemas'] || []) || [];

    return (
        <div className="overflow-y-scroll h-[360px]">
            <table className="w-full">
                <thead className="border-t-1 border-b-1 border-white-50">
                    <tr className={' h-36'}>
                        <th className={'text-body-bold-12 text-center'}>#</th>
                        <th className={'text-body-bold-12 text-left'}>Cinema</th>

                        <th className={'text-body-bold-12'}>
                            <div className={'flex justify-center content-center'}>
                                <Button
                                    style={ButtonStyle.text_only_no_padding}
                                    className={'text-body-bold-12'}
                                    labelClassName={`text-body-bold-12 ${filterByMA ? 'text-cyan' : 'text-white'}`}
                                    onClick={() => setFilterByMA(!filterByMA)}>
                                    MA&nbsp;
                                </Button>
                                {filterByMA !== null && (
                                    <Button
                                        style={ButtonStyle.text_only_no_padding}
                                        leadingIcon={<IconDeselect16 />}
                                        onClick={() => setFilterByMA(null)}></Button>
                                )}
                            </div>
                        </th>
                        <th className={'text-body-bold-12'}>
                            <div className="flex justify-start content-center">Growth</div>
                        </th>
                        <th className={'text-body-bold-12'}>
                            <div className="flex justify-end content-center">Total</div>
                        </th>
                    </tr>
                </thead>
                <tbody className="">
                    {isLoadingCinemaUserGrowthTopResponse && (
                        <tr>
                            <td colSpan={5}>
                                <ListLoadingSpinner />
                            </td>
                        </tr>
                    )}
                    {topCinemasWithUserGrowthMetric?.length > 0
                        ? topCinemasWithUserGrowthMetric?.map((cinema, index) => (
                              <tr
                                  key={index}
                                  className={' even:bg-white-20 h-36'}>
                                  <td className={'text-body-bold-12 text-center'}>{index + 1}</td>
                                  <td className={'text-body-bold-12 text-left'}>
                                      <Link
                                          target="_blank"
                                          href={
                                              process.env.NEXT_PUBLIC_ENV_CINEMA_HOST_NAME + '/?cinemaId=' + cinema.slug
                                          }>
                                          {cinema.name}
                                      </Link>
                                      <div className="text-[9px] font-light">{cinema.city}</div>
                                  </td>
                                  <td className={'text-body-bold-12'}>
                                      <MACellContent
                                          hasMA={
                                              cinema._embedded?.cinemaConfiguration?.hasMarketingAssistantBooked ===
                                              true
                                          }
                                      />
                                  </td>
                                  <td className={'text-body-bold-12'}>
                                      <ArrowCellContent
                                          count={cinema.metricCustomersCount}
                                          comparisonCount={cinema.metricCompareCustomersCount}
                                      />
                                  </td>
                                  <td className="text-body-bold-12 text-gray text-right">
                                      {new Intl.NumberFormat(locale, { style: 'decimal' }).format(
                                          cinema.metricCustomersTotalCount
                                      )}
                                  </td>
                              </tr>
                          ))
                        : !isLoadingCinemaUserGrowthTopResponse && (
                              <tr>
                                  <td
                                      colSpan={5}
                                      className="row-span-5">
                                      <span className="block p-24 text-center opacity-50 select-none">no data</span>
                                  </td>
                              </tr>
                          )}

                    <tr className="loader-element-cinemas-top" />
                </tbody>
            </table>
        </div>
    );
}
