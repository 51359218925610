import classnames from 'classnames';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { useQuery } from 'react-query';

import DashboardAnalysisModal from '@/components/pages/dashboard/DashboardAnalysisModal';
import CinemaUserGrowthTopAndFlopCard from '@/components/ui/dashboard/CinemaUserGrowthTopAndFlopCard';
import DashboardUserGrowth from '@cineamo/legacy-frontend-lib/src/components/page/dashboard/DashboardUserGrowth';
import AdminCard from '@cineamo/legacy-frontend-lib/src/components/ui/cards/AdminCard';
import InfoCardSmall from '@cineamo/legacy-frontend-lib/src/components/ui/cards/InfoCardSmall';

import { DeviceType } from '@cineamo/legacy-frontend-lib/src/types/DeviceType.types';

import { getUsersCount } from '@cineamo/legacy-frontend-lib/src/api/analytics/analytics-api';
import { getDeals } from '@cineamo/legacy-frontend-lib/src/api/deal/deal-api';

import { tailwindColors } from '@/src/helper/tailwindConfigHelper';
import { formatDate_yyyyMMddTHHmmssZ } from '@cineamo/legacy-frontend-lib/src/helper/date-helper';

import withMiddlewareDefault from '@/src/middleware/withMiddlewareDefault';

import IconAnalysis24 from '@cineamo/legacy-frontend-lib/src/icons/ic-analysis';

import { localeMiddleware } from '@/src/middleware';

export default function Dashboard() {
    const router = useRouter();
    const locale = router.locale;

    const { t } = useTranslation('dashboard');

    // Fetch all users to get total items number
    const { data: appUsersCount, isLoading: isLoadingAppUsersCount } = useQuery(
        ['app-users-count'],
        async () => {
            return await getUsersCount({
                deviceType: DeviceType.MOBILE
            });
        },
        {
            staleTime: 60000
        }
    );

    // Fetch all deals to get total items number
    const { data: dealsResponse, isLoading: isLoadingActiveDeals } = useQuery(
        'active-deals',
        async () => {
            return await getDeals({
                activeDatetime: formatDate_yyyyMMddTHHmmssZ(new Date())
            });
        },
        {
            staleTime: 60000
        }
    );

    const activeDeals = dealsResponse?._total_items || 0;

    const [showAnalysisModal, setShowAnalysisModal] = useState<boolean>(false);

    function AnalysisContents(props: { className?: string; labelClassName?: string; valueClassName?: string }) {
        const { labelClassName, valueClassName, className } = props;
        return (
            <div className={className}>
                <InfoCardSmall
                    label={t('current-app-users')}
                    value={appUsersCount}
                    labelClassName={labelClassName && labelClassName}
                    valueClassName={valueClassName && valueClassName}
                    isLoading={isLoadingAppUsersCount}
                />
                <InfoCardSmall
                    label={t('deals-online')}
                    value={activeDeals}
                    labelClassName={labelClassName && labelClassName}
                    valueClassName={valueClassName && valueClassName}
                    isLoading={isLoadingActiveDeals}
                />
            </div>
        );
    }

    return (
        <>
            <DashboardAnalysisModal
                modalIsOpen={showAnalysisModal}
                setModalIsOpen={setShowAnalysisModal}>
                <AnalysisContents
                    className="space-y-24"
                    labelClassName="text-darkBlue text-opacity-50"
                    valueClassName="text-darkBlue"
                />
            </DashboardAnalysisModal>

            <div className="text-white flex flex-row h-full">
                <div className="w-full p-3 md:p-24">
                    <div className="pb-16 lg:pb-0 lg:hidden flex justify-end">
                        <div
                            className="cursor-pointer"
                            onClick={() => setShowAnalysisModal(true)}>
                            <IconAnalysis24 className="text-white" />
                        </div>
                    </div>
                    <div className="space-y-24 flex flex-col">
                        {/*<ChartCard*/}
                        {/*    className="w-full lg:w-400 xl:w-500"*/}
                        {/*    title={`${newUsersLast30Days} new users the last 30 days`}*/}
                        {/*    type="bar"*/}
                        {/*    data={userChartData}*/}
                        {/*/>*/}

                        {/* Start Top and Flop Card */}
                        <CinemaUserGrowthTopAndFlopCard locale={locale} />
                        {/* End Top and Flop Card */}

                        <AdminCard title={'Current App users'}>
                            <span className="text-body-14">{appUsersCount}</span>
                        </AdminCard>
                        <DashboardUserGrowth
                            lineChartStyle={{
                                ticks: {
                                    color: tailwindColors.white['50']
                                },
                                borderColor: tailwindColors.cyan.DEFAULT
                            }}
                            moreAnalyticsUrl={'/analytics'}
                            locale={locale}
                            t={t}
                        />
                    </div>
                </div>

                <div
                    className={classnames(
                        'h-full hidden lg:block w-300 lg:border-l-1 border-white border-opacity-20',
                        'transform transition-all duration-300'
                    )}>
                    <div className="p-24">
                        <AnalysisContents className="space-y-24" />
                    </div>
                </div>
            </div>
        </>
    );
}

export const getServerSideProps = withMiddlewareDefault(localeMiddleware(['cinema', 'dashboard']));
