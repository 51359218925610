import { useTranslation } from 'next-i18next';
import React from 'react';

import Modal, { ModalProps } from '@cineamo/legacy-frontend-lib/src/components/ui/modal/Modal';

type DashboardsAnalysisModalProps = {
    children?: React.ReactNode;
} & ModalProps;

export default function DashboardAnalysisModal(props: DashboardsAnalysisModalProps) {
    const { children } = props;

    const { t } = useTranslation('dashboard');

    return (
        <Modal
            {...props}
            onClose={null}
            title={t('analysis')}>
            <div className="space-y-24">{children}</div>
        </Modal>
    );
}
