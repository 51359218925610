import {CustomSVGProps} from '../types/CustomSVGProps.types';

export function IconMarketingAssistant48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48"
             height="48"
             viewBox="0 0 48 48"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3 20C3 17.2386 5.23858 15 8 15C10.7614 15 13 17.2386 13 20V30C13 32.7614 10.7614 35 8 35C5.23858 35 3 32.7614 3 30V20ZM8 17C6.34315 17 5 18.3431 5 20V30C5 31.6569 6.34315 33 8 33C9.65685 33 11 31.6569 11 30V20C11 18.3431 9.65685 17 8 17Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17 38C17 36.3431 18.3431 35 20 35H28C29.6569 35 31 36.3431 31 38V40C31 41.6569 29.6569 43 28 43H20C18.3431 43 17 41.6569 17 40V38ZM20 37C19.4477 37 19 37.4477 19 38V40C19 40.5523 19.4477 41 20 41H28C28.5523 41 29 40.5523 29 40V38C29 37.4477 28.5523 37 28 37H20Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M35 20C35 17.2386 37.2386 15 40 15C42.7614 15 45 17.2386 45 20V30C45 32.7614 42.7614 35 40 35C37.2386 35 35 32.7614 35 30V20ZM40 17C38.3431 17 37 18.3431 37 20V30C37 31.6569 38.3431 33 40 33C41.6569 33 43 31.6569 43 30V20C43 18.3431 41.6569 17 40 17Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M40.4472 34.8944L30.4472 39.8944L29.5527 38.1056L39.5527 33.1056L40.4472 34.8944Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M24 2C15.7157 2 9 8.71573 9 17H7C7 7.61116 14.6112 0 24 0C33.3888 0 41 7.61116 41 17H39C39 8.71573 32.2843 2 24 2Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>


);
}

export function IconMarketingAssistant24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24"
             height="24"
             viewBox="0 0 24 24"
             fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1 10C1 8.34315 2.34315 7 4 7C5.65685 7 7 8.34315 7 10V15C7 16.6569 5.65685 18 4 18C2.34315 18 1 16.6569 1 15V10ZM4 9C3.44772 9 3 9.44772 3 10V15C3 15.5523 3.44772 16 4 16C4.55228 16 5 15.5523 5 15V10C5 9.44772 4.55228 9 4 9Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 19C8 17.8954 8.89543 17 10 17H14C15.1046 17 16 17.8954 16 19V21C16 22.1046 15.1046 23 14 23H10C8.89543 23 8 22.1046 8 21V19ZM14 19H10V21H14V19Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M17 10C17 8.34315 18.3431 7 20 7C21.6569 7 23 8.34315 23 10V15C23 16.6569 21.6569 18 20 18C18.3431 18 17 16.6569 17 15V10ZM20 9C19.4477 9 19 9.44772 19 10V15C19 15.5523 19.4477 16 20 16C20.5523 16 21 15.5523 21 15V10C21 9.44772 20.5523 9 20 9Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M20.5145 17.8574L15.5145 20.8574L14.4855 19.1425L19.4855 16.1425L20.5145 17.8574Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M12 2C8.13401 2 5 5.13401 5 9H3C3 4.02944 7.02944 0 12 0C16.9706 0 21 4.02944 21 9H19C19 5.13401 15.866 2 12 2Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>

);
}

export function IconMarketingAssistant16(props: CustomSVGProps): JSX.Element {
    return (

    <svg {...props} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd"
              d="M8 2C5.89229 2 4.08919 3.30415 3.3536 5.14952C4.31399 5.49837 5 6.41907 5 7.5V10.5C5 11.8807 3.88071
              13 2.5 13C1.11929 13 0 11.8807 0 10.5V7.5C0 6.59929 0.47633 5.80983 1.19083 5.36978C1.92545 2.29004
              4.69529 0 8 0C11.3047 0 14.0745 2.29004 14.8092 5.36978C15.5237 5.80983 16 6.59929 16 7.5V10.5C16 11.7552
               15.075 12.7943 13.8695 12.9729L10.5523 15.4608C10.2451 15.7924 9.80588 16 9.31819 16H6.68181C5.75297 16
                5 15.247 5 14.3182V12.6818C5 11.753 5.75297 11 6.68181 11H9.31819C10.2282 11 10.9695 11.7228 10.9991
                12.6257L11.6264 12.1552C11.2366 11.7143 11 11.1348 11 10.5V7.5C11 6.41907 11.686 5.49837 12.6464
                5.14952C11.9108 3.30415 10.1077 2 8 2ZM2 7.5C2 7.22386 2.22386 7 2.5 7C2.77614 7 3 7.22386 3 7.5V10.5C3
                 10.7761 2.77614 11 2.5 11C2.22386 11 2 10.7761 2 10.5V7.5ZM13 7.5C13 7.22386 13.2239 7 13.5 7C13.7761 7
                  14 7.22386 14 7.5V10.5C14 10.7761 13.7761 11 13.5 11C13.2239 11 13 10.7761 13 10.5V7.5ZM7 13V14H9V13H7Z"
              fill={props.fill || 'currentColor'}/>
    </svg>


);
}
