import { UrlQueryParameter, buildUrlQuery } from '../../helper/url-query-helper';
import { DealCategory } from '../../models/deals/DealCategoryEnum.types';
import { DealDto } from '../../models/deals/DealDto';
import { DealInputDto } from '../../models/deals/DealInputDto.types';
import { ApiErrorResponse } from '../ApiErrorResponse.types';
import { DealListResponse } from '../ApiListResponse.types';
import { ApiResponse } from '../ApiResponse.types';
import apiClient from '../cineamoApiClient';

// <------------------------ Deals ------------------------>

type GetDealsParams = UrlQueryParameter & {
    cinemas?: number[] | string[];
    longitude?: number;
    latitude?: number;
    distance?: number;
    category?: DealCategory;
    activeDatetime?: string;
};

/**
 * This endpoint returns a list of deals.
 *
 * @example
 * // Get a list of deals:
 * getDeals({...GetDealsParams});
 *
 * @param params
 * @returns {ApiResponse<DealListResponse>}
 */

export async function getDeals(params?: GetDealsParams): Promise<ApiResponse<DealListResponse> | null> {
    return apiClient
        .get(`/deals${buildUrlQuery(params)}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Deals by Cinema ------------------------>

/**
 * This endpoint returns a deal.
 *
 * @example
 * // Get a deal by id:
 * getDealById(1);
 *
 * @param dealId
 * @returns {ApiResponse<DealDto>}
 */

export async function getDealById(dealId: number | string): Promise<ApiResponse<DealDto>> {
    return apiClient
        .get(`/deals/${dealId}`)
        .then((response) => response.data)
        .catch(() => {
            return null;
        });
}

// <------------------------ Create Deal ------------------------>

/**
 * This endpoint returns a deal.
 *
 * @example
 * // Create a deal:
 * createDeal({...deal});
 *
 * @param deal
 * @returns {ApiResponse<DealDto> | ApiErrorResponse<DealDto>}
 */

export async function createDeal(deal: DealInputDto): Promise<ApiResponse<DealDto> | ApiErrorResponse<DealInputDto>> {
    return apiClient
        .post('/deals', deal)
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Update Deal ------------------------>

/**
 * This endpoint returns a deal.
 *
 * @example
 * // Update a deal:
 * createDeal(1, {...deal});
 *
 * @param deal
 * @param dealId
 * @returns {ApiResponse<DealDto> | ApiErrorResponse<DealDto>}
 */

export async function updateDeal(
    dealId: number | string,
    deal: DealInputDto
): Promise<ApiResponse<DealDto> | ApiErrorResponse<DealInputDto>> {
    return apiClient
        .patch(`/deals/${dealId}`, JSON.stringify(deal))
        .then((response) => response.data)
        .catch((error) => error);
}

// <------------------------ Delete Deal ------------------------>

/**
 * This endpoint returns a boolean.
 *
 * @example
 * // Delete a deal by Id:
 * deleteDealById(1);
 * @param dealId
 * @returns {boolean}
 */

export async function deleteDealById(dealId: number | string): Promise<boolean> {
    return apiClient
        .delete(`/deals/${dealId}`)
        .then(() => true)
        .catch(() => {
            return false;
        });
}
