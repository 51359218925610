import React from 'react';

import { IconDownArrow16 } from '@cineamo/legacy-frontend-lib/src/icons/arrows/ic-downArrow';
import { IconRightArrow16 } from '@cineamo/legacy-frontend-lib/src/icons/arrows/ic-rightArrow';
import { IconUpArrow16 } from '@cineamo/legacy-frontend-lib/src/icons/arrows/ic-upArrow';

export default function ArrowCellContent(props: { count: number; comparisonCount: number }) {
    const { count, comparisonCount } = props;

    function returnCalculatedGrowth(count: number, comparisonCount: number): string {
        if (!isFinite(comparisonCount / count) || !isFinite(count / comparisonCount)) {
            return '-%';
        }
        if (comparisonCount > count) {
            const result = comparisonCount / count - 1;
            return '-' + Math.round(result * 100) + '%';
        }
        if (count > comparisonCount) {
            const result = count / comparisonCount - 1;
            return '+' + Math.round(result * 100) + '%';
        } else {
            return '0%';
        }
    }

    return (
        <div className={`flex justify-start content-center ${count < 1 ? 'text-berry-light' : 'text-cyan'}`}>
            {count < 0 && (
                <span className="flex space-x-2">
                    <IconDownArrow16 /> <span>-</span>
                </span>
            )}
            {count > 0 ? (
                <span className="flex space-x-2">
                    <IconUpArrow16 /> <span>+</span>
                </span>
            ) : (
                <span className="flex space-x-2">
                    <IconRightArrow16 />
                    <span></span>
                </span>
            )}
            {count}
            <span className="text-gray">({returnCalculatedGrowth(count, comparisonCount)})</span>
        </div>
    );
}
