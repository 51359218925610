import {CustomSVGProps} from '../types/CustomSVGProps.types';

export default function IconAnalysis24(props: CustomSVGProps) {
    return (
        <svg
            {...props}
            width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="2" y="15" width="4" height="8" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="18" y="9" width="4" height="14" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="10" y="5" width="4" height="18" stroke={props.stroke || 'currentColor'} strokeWidth="2"/>
            <rect x="1.5" y="22.5" width="21" height="1" stroke={props.stroke || 'currentColor'}/>
        </svg>
    );
}