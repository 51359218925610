import React from 'react';

import { IconMarketingAssistant16 } from '@cineamo/legacy-frontend-lib/src/icons/ic-marketingAssistant';

export default function MACellContent(props: { hasMA: boolean }) {
    const { hasMA } = props;

    return (
        <div className={'flex justify-center content-center'}>
            {hasMA ? (
                <IconMarketingAssistant16 className="text-cyan" />
            ) : (
                <IconMarketingAssistant16 className="text-white-50" />
            )}
        </div>
    );
}
