import classnames from 'classnames';
import React, { useState } from 'react';

import Dropdown, { DropdownNumberOption, DropdownOption, DropdownStyle } from '@/components/ui/select/Dropdown';
import InputLabel from '@cineamo/legacy-frontend-lib/src/components/ui/label/InputLabel';

export type BasicDropdownProps = {
    className?: string;
    style?: DropdownStyle;
    label?: string;
    error?: string;
    placeholder?: string | JSX.Element;
    currentValue?: DropdownOption | DropdownNumberOption | number;
    options: DropdownOption[] | DropdownNumberOption[];
    onChange?: (selectedItem: DropdownOption, index: number) => void;
    disabled?: boolean;
};

function BasicDropdown(props: BasicDropdownProps): JSX.Element {
    const { className, style, label, placeholder, currentValue, options, onChange, disabled } = props;

    const [focused, setFocused] = useState(false);

    let labelColor = 'text-gray-transparent';
    let focusedLabelColor = 'text-cyan';

    switch (style) {
        case DropdownStyle.dropdown_on_white_bg:
            labelColor = 'text-darkBlue-transparent';
            focusedLabelColor = 'text-darkBlue';
            break;
        case DropdownStyle.light:
            labelColor = 'text-darkBlue-transparent';
            focusedLabelColor = 'text-darkBlue';
            break;
        case DropdownStyle.light_on_white_bg:
            labelColor = 'text-darkBlue-transparent';
            focusedLabelColor = 'text-darkBlue';
            break;
        default:
            break;
    }

    function enterFocus() {
        if (disabled !== true) {
            setFocused(true);
        }
    }

    function exitFocus() {
        setFocused(false);
    }

    function onChangeInternal(selectedItem: DropdownOption) {
        if (onChange) {
            onChange(
                selectedItem,
                options.findIndex((option) => option === selectedItem)
            );
        }
    }

    return (
        <div
            className={`${className || ''} w-full flex flex-col`}
            onFocus={enterFocus}
            onBlur={exitFocus}>
            {label && (
                <InputLabel
                    label={label}
                    labelStyle={classnames(
                        { 'opacity-100': focused },
                        { [focusedLabelColor]: focused },
                        { [labelColor]: !focused }
                    )}
                />
            )}
            <Dropdown
                className="w-auto h-48 z-40"
                style={style}
                placeholder={placeholder}
                currentValue={typeof currentValue === 'number' ? options[currentValue] : currentValue}
                onChange={onChangeInternal}
                options={options}
            />
        </div>
    );
}

export default BasicDropdown;
