import { DateTime } from 'luxon';
import React, { useState } from 'react';

import CinemaFlopUserGrowthTable from '@/components/ui/dashboard/CinemaFlopUserGrowthTable';
import CinemaTopUserGrowthTable from '@/components/ui/dashboard/CinemaTopUserGrowthTable';
import { DropdownNumberOption, DropdownStyle } from '@/components/ui/select/Dropdown';
import AdminCard from '@cineamo/legacy-frontend-lib/src/components/ui/cards/AdminCard';

import BasicDropdown from '../input/BasicDropdown';

const DropdownOptions: DropdownNumberOption[] = [
    { value: 0, label: 'Yesterday' },
    { value: 1, label: 'Last 24 hours' },
    { value: 7, label: 'Last 7 days' },
    { value: 14, label: 'Last 14 days' },
    { value: 30, label: 'Last 30 days' },
    { value: 90, label: 'Last 90 days' },
    { value: 180, label: 'Last 180 days' },
    { value: 365, label: 'Last 365 days' }
];

export default function CinemaUserGrowthTopAndFlopCard(props: { locale: string }) {
    const { locale } = props;
    const [timeSpanInDays, setTimeSpanInDays] = useState<number>(DropdownOptions[0].value);
    const metricEndDatetime =
        timeSpanInDays !== 0
            ? DateTime.utc().set({ milliseconds: 0 }).toISO({ suppressMilliseconds: true }).toString()
            : DateTime.utc()
                  .set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                  .toISO({ suppressMilliseconds: true })
                  .toString();
    const metricStartDatetime =
        timeSpanInDays !== 0
            ? DateTime.utc()
                  .minus({ days: timeSpanInDays })
                  .set({ milliseconds: 0 })
                  .toISO({ suppressMilliseconds: true })
                  .toString()
            : DateTime.utc()
                  .minus({ days: 1 })
                  .set({ hour: 0, minutes: 0, seconds: 0, milliseconds: 0 })
                  .toISO({ suppressMilliseconds: true })
                  .toString();

    return (
        <AdminCard
            titleClassName={''}
            className=""
            leadingComponent={
                <div className="flex justify-between w-full">
                    <h2 className="text-body-bold-16 self-center">User Growth - Top & Flop</h2>
                    <div>
                        <BasicDropdown
                            className=""
                            style={DropdownStyle.light_on_white_bg}
                            options={DropdownOptions}
                            currentValue={DropdownOptions.find((option) => option.value === timeSpanInDays)}
                            onChange={(selectedItem) => {
                                setTimeSpanInDays(Number(selectedItem.value));
                            }}
                        />
                    </div>
                </div>
            }>
            <div className="grid md:grid-cols-2 gap-y-20 md:gap-0">
                <CinemaTopUserGrowthTable
                    startDatetime={metricStartDatetime}
                    endDatetime={metricEndDatetime}
                    locale={locale}
                />

                <CinemaFlopUserGrowthTable
                    startDatetime={metricStartDatetime}
                    endDatetime={metricEndDatetime}
                    locale={locale}
                />
            </div>
        </AdminCard>
    );
}
