import { CustomSVGProps } from '../../types/CustomSVGProps.types';

export function IconDownArrow48(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="48" 
             height="48" 
             viewBox="0 0 48 48"
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd" 
                  d="M24 47.4142L12.2929 35.7071L13.7071 34.2929L24 44.5858L34.2929 34.2929L35.7071 35.7071L24 47.4142Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M25 4.07697e-06L25 46L23 46L23 0L25 4.07697e-06Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconDownArrow24(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="24" 
             height="24"
             viewBox="0 0 24 24" 
             fill="none" 
             xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M12 20.5858L6.70711 15.2929L5.29289 16.7071L12 23.4142L18.7071 16.7071L17.2929 15.2929L12 20.5858Z" 
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd" 
                  d="M13 1.21182e-07L13 22L11 22L11 0L13 1.21182e-07Z"
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}

export function IconDownArrow16(props: CustomSVGProps): JSX.Element {
    return (
        <svg {...props}
             width="16" 
             height="16"
             viewBox="0 0 16 16" 
             fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd"
                  clipRule="evenodd"
                  d="M8 12.5858L3.70711 8.29289L2.29289 9.70711L8.00001 15.4142L13.7071 9.7071L12.2929 8.29289L8 12.5858Z"
                  fill={props.fill || 'currentColor'}/>
            <path fillRule="evenodd" 
                  clipRule="evenodd"
                  d="M9 1.15875e-06L9 13L7 13L7 9.53674e-07L9 1.15875e-06Z" 
                  fill={props.fill || 'currentColor'}/>
        </svg>
    );
}