import classnames from 'classnames';

import SkeletonView, { Skeletonable } from '../skeleton-view/SkeletonView';

type InfoCardSmallProps = {
    label: string | JSX.Element;
    subLabel?: string;
    value?: number | string | JSX.Element;
    icon?: JSX.Element;
    className?: string;
    labelClassName?: string;
    valueClassName?: string;
    labelDefaultCase?: boolean;
} & Skeletonable;

function InfoCardSmall(props: InfoCardSmallProps) {
    const { label, subLabel, icon, value, className, valueClassName, labelClassName, isLoading } = props;

    return (
        <div className={classnames('text-white-50', className)}>
            <div
                className={classnames(labelClassName, 'text-body-bold-10 tracking-wider whitespace-pre-wrap', {
                    uppercase: props.labelDefaultCase !== true,
                    'flex flex-col': subLabel
                })}>
                {label && <span>{label}</span>}
                {subLabel && <span>{subLabel}</span>}
            </div>
            <div className="flex items-center">
                {icon && <div className="mr-10 p-1">{icon}</div>}
                {!isLoading && value !== undefined && value !== null && (
                    <div className="text-headline-18 text-cyan">
                        <span className={valueClassName}>{value}</span>
                    </div>
                )}
                {isLoading && <SkeletonView />}
            </div>
        </div>
    );
}

export default InfoCardSmall;
